// set basepath of project for webpack
import { basePath } from './core/config';
__webpack_public_path__ = `${basePath}/assets/frontend/js/`;

import Swupjs from 'swupjs';

let options = {
    // ghost místama vkládá absolutní odkazy, proto je potřeba přidat i adresy s doménou
    elements: ['#swup', '#header'],
    cache: true,
    pageClassPrefix: '',
    scroll: true,
    debugMode: true,
    preload: true,
    support: true,
    animateScrollToAnchor: false,
    doScrollingRightAway: false,
    animateScrollOnMobile: false
}

document.addEventListener('swup:contentReplaced', event => {
    window.ga('set', 'page', window.location.href);
    window.ga('send', 'pageview');
});

const swupjs = new Swupjs(options);

export default swupjs;
